.carousel.carousel-slider {
    margin: 0px auto;
    overflow: visible;
  }
  
  .carousel .slide {
    color: white;
    background: rgb(55, 55, 55);
  }
  
  .carousel .slide-holder {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    height: 100%;
  }
  .carousel .slide img {
    margin: -5px;
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
  .carousel .slide iframe {
    margin: 30px;
    width: 35%;
  }
  .carousel .text-container {
    padding: 30px;
    text-align: left;
  }
  
  .carousel .text-container h2 {
    margin-top: 0px;
  }
  
  .carousel .text-container p:last-child {
    margin-bottom: 0px;
  }
  
  .carousel .control-dots {
    bottom: -40px;
  }
  
  .carousel .thumbs-wrapper {
    margin: 30px;
  }
  /* https://codesandbox.io/s/zribp?file=/src/index.js:2377-2537 */