.App {
    font-family: sans-serif;
    text-align: center;
  }
  
  .card {
    background-color: rgb(137, 137, 186) !important;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    text-transform: capitalize;
    color: white;
  }
  
  .tags {
    position: absolute;
    top: 5px;
    left: 0;
  }
  .tags > span {
    display: inline-block;
    padding: 4px;
    margin-left: 3px;
    font-size: 14px;
    background: gray;
    min-width: 40px;
  }
  